<template>
  <table :class="['table',{'table--type-zebra' : type === 'zebra'}]">
		<slot></slot>
	</table>
</template>

<script>
export default {
  props: {
    type: String,
	},
	data(){
		return{
			
		}
	},
	methods: {
	},
}

</script>

<style lang="scss" scoped>
@import '@/assets/scss/breakpoints.scss';
.table{
	display: block;
	border-collapse: collapse;
	
	@include breakpoint(sm){
		display: table;
		width: var(--table-width);
		margin-bottom: var(--table-margin-bottom);
	}
}
</style>

<!-- Used in : DS -->